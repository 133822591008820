import React from 'react';

function Footer({ translations }) {
  return (
    <footer>
      <p>&copy; {translations.footer}</p>
    </footer>
  );
}

export default Footer;