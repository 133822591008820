export const translations = {
  en: {
    home: "Home",
    solutions: "Solutions",
    contactUs: "Contact Us",
    title: "Your own GenAI that accompanies your employees.",
    meetAISME: "Meet AI SME",
    meetAISMEDesc: "A 24/7 subject matter expert aims to answer your employees' questions.",
    learnMore: "Learn more",
    buildKnowledgeBase: "Build knowledge base",
    buildKnowledgeBaseDesc: "Unleash the power of knowledge within your own company.",
    getStarted: "Get started now",
    businessScenarios: "Business scenarios",
    salesTraining: "Sales training",
    salesTrainingDesc: "Focus on the broader principles. Leave the details to AI.",
    searchingAnswers: "Searching for answers",
    searchingAnswersDesc: "Calling peers/managers for questions? Just ask AI.",
    knowledgeSharing: "Knowledge sharing",
    knowledgeSharingDesc: "Talk to AI as a seasoned employee for best practices.",
    contactDescription: "For any inquiries, please contact us at:",
    solutionTitle: "The Solution: AI SME",
    jobsToBeDone: "Jobs-to-be-done",
    job1: "A real-time 24/7 knowledge expert for every sales (and other employees)",
    job2: "Accurate answers/content generation based on provided source documents",
    job3: "A data secure procedure that guarantees zero leakage of sensitive information",
    job4: "An interactive and multi-modal user experience that enhances learning effectiveness",
    aiSMESolution: "Product Features",
    usageScenario: "Usage Scenario",
    usageScenarioDesc: "Make organizational knowledge easily accessible and usable for all employees. Including",
    usageScenario1: "New employees onboarding",
    usageScenario2: "Regular training session preparation",
    usageScenario3: "Out-of-office knowledge retrieval / consultation",
    usageScenario4: "Training material creation",
    knowledgeBase: "One-stop Knowledge Base",
    knowledgeBaseDesc: "Make enterprise knowledge searchable, digestible, and contextualized",
    knowledgeBase1: "Company policies, procedures",
    knowledgeBase2: "Product specifications",
    knowledgeBase3: "Best practices",
    knowledgeBase4: "Integration with existing databases",
    solution1: "Integration with real-time chat app, answer is grounded by citation",
    solution2: "Can be completely private-deployed for better data protection",
    solution3: "Multi-model engagement including texts, pictures, AI avatars ",
    solution4: "Quality-in, quality-out. Support all common type of files",
    footer: "2024 Longway AI. All rights reserved."
  },
  zh: {
    home: "首页",
    solutions: "解决方案",
    contactUs: "联系我们",
    title: "一个完全属于您企业的GenAI，时刻陪伴您的员工。",
    meetAISME: "AI问答专家",
    meetAISMEDesc: "24/7全天候主题专家，旨在回答您员工的问题。",
    learnMore: "了解更多",
    buildKnowledgeBase: "构建知识库",
    buildKnowledgeBaseDesc: "释放公司内部知识的力量。",
    getStarted: "立即开始",
    businessScenarios: "业务场景",
    salesTraining: "销售培训",
    salesTrainingDesc: "专注于更广泛的原则。将细节留给AI。",
    searchingAnswers: "寻找答案",
    searchingAnswersDesc: "需要打电话给同事/经理询问问题？直接问AI。",
    knowledgeSharing: "知识共享",
    knowledgeSharingDesc: "与AI交谈，如同与经验丰富的员工交流最佳实践。",
    contactDescription: "如有任何疑问，请通过以下方式联系我们：",
    solutionTitle: "解决方案：AI有问必答",
    jobsToBeDone: "产品价值",
    job1: "为每个销售（和其他员工）提供实时24/7知识专家",
    job2: "基于提供的源文档生成准确的答案/内容",
    job3: "确保敏感信息零泄露的数据安全程序",
    job4: "增强学习效果的交互式多模态用户体验",
    aiSMESolution: "产品优势",
    usageScenario: "使用场景",
    usageScenarioDesc: "使组织知识易于所有员工访问和使用。包括",
    usageScenario1: "新员工入职",
    usageScenario2: "常规培训会议准备",
    usageScenario3: "办公室外知识检索/咨询",
    usageScenario4: "培训材料创建",
    knowledgeBase: "一站式知识库",
    knowledgeBaseDesc: "使企业知识可搜索、易消化和具有上下文",
    knowledgeBase1: "公司政策、程序",
    knowledgeBase2: "产品规格",
    knowledgeBase3: "最佳实践",
    knowledgeBase4: "与现有数据库集成",
    solution1: "与实时聊天应用集成，答案基于引用来源",
    solution2: "可完全私有部署以更好地保护数据",
    solution3: "多模态交互，包括文本、图片、AI头像",
    solution4: "质量输入，质量输出。支持所有常见文件类型",
    footer: "2024 朗唯九道（上海）科技有限公司。版权所有。"
  }
};