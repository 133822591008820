import React from 'react';

function Contact({ translations }) {
  return (
    <div className="contact-page">
      <div className="contact-content">
        <h1>{translations.contactUs}</h1>
        <p>{translations.contactDescription}</p>
        <a href="mailto:contact@long-agi.com" className="email-link">
          contact@long-agi.com
        </a>
      </div>
    </div>
  );
}

export default Contact;