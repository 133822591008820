import React from 'react';
import { Link } from 'react-router-dom';

function Header({ language, toggleLanguage, translations }) {
  return (
    <header>
      <nav>
        <div className="logo">
          <Link to="/">
            <img src="/longway-logo.png" alt="LONGWAY AI" />
          </Link>
        </div>
        <ul>
          <li><Link to="/">{translations.home}</Link></li>
          <li><Link to="/solutions">{translations.solutions}</Link></li>
          <li><Link to="/contact">{translations.contactUs}</Link></li>
        </ul>
        <button className="language-toggle" onClick={toggleLanguage}>
          {language === 'en' ? '中文' : 'English'}
        </button>
      </nav>
    </header>
  );
}

export default Header;