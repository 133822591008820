import React from 'react';

function Home({ translations }) {
  return (
    <div className="home">
      <div className="hero-section">
        <div className="hero-content">
          <h1>{translations.title}</h1>
          <div className="cta-cards">
            <div className="cta-card">
              <div>
                <h2>{translations.meetAISME}</h2>
                <p>{translations.meetAISMEDesc}</p>
              </div>
              <div className="cta-button-container">
                <button className="dark-button" onClick={() => window.location.href = '/solutions'}>{translations.learnMore}</button>
              </div>
            </div>
            <div className="cta-card">
              <div>
                <h2>{translations.buildKnowledgeBase}</h2>
                <p>{translations.buildKnowledgeBaseDesc}</p>
              </div>
              <div className="cta-button-container">
                <button className="dark-button" onClick={() => window.location.href = '/solutions'}>{translations.getStarted}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="our-work">
        <h2>{translations.businessScenarios}</h2>
        <div className="work-cards">
          <div className="work-card">
            <span className="category">{translations.salesTraining}</span>
            <h3>{translations.salesTrainingDesc}</h3>
          </div>
          <div className="work-card">
            <span className="category">{translations.searchingAnswers}</span>
            <h3>{translations.searchingAnswersDesc}</h3>
          </div>
          <div className="work-card">
            <span className="category">{translations.knowledgeSharing}</span>
            <h3>{translations.knowledgeSharingDesc}</h3>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;