import React from 'react';

function Solutions({ translations }) {
  return (
    <div className="solutions-page">
      <h1 className="solutions-title">{translations.solutionTitle}</h1>
      
      <section className="solution-section blue-strand">
        <div className="section-content">
          <h2>{translations.jobsToBeDone}</h2>
          <ul>
            <li>• {translations.job1}</li>
            <li>• {translations.job2}</li>
            <li>• {translations.job3}</li>
            <li>• {translations.job4}</li>
          </ul>
        </div>
        <img src="/components/solutions/jobs-to-be-done.jpeg" alt={translations.jobsToBeDone} className="section-image" />
      </section>
      
      <section className="solution-section align-left">
        <img src="/components/solutions/product-features.jpeg" alt={translations.aiSMESolution} className="section-image" />
        <div className="section-content">
          <h2>{translations.aiSMESolution}</h2>
          <ul>
            <li>• {translations.solution1}</li>
            <li>• {translations.solution2}</li>
            <li>• {translations.solution3}</li>
            <li>• {translations.solution4}</li>
          </ul>
        </div>
      </section>
      
      <section className="solution-section blue-strand">
        <div className="section-content">
          <h2>{translations.usageScenario}</h2>
          <p>{translations.usageScenarioDesc}</p>
          <ul>
            <li>• {translations.usageScenario1}</li>
            <li>• {translations.usageScenario2}</li>
            <li>• {translations.usageScenario3}</li>
            <li>• {translations.usageScenario4}</li>
          </ul>
        </div>
        <img src="/components/solutions/usage-scenario.jpeg" alt={translations.usageScenario} className="section-image" />
      </section>
      
      <section className="solution-section align-left">
        <img src="/components/solutions/one-stop-knowledge-base.jpeg" alt={translations.knowledgeBase} className="section-image" />
        <div className="section-content">
          <h2>{translations.knowledgeBase}</h2>
          <p>{translations.knowledgeBaseDesc}</p>
          <ul>
            <li>• {translations.knowledgeBase1}</li>
            <li>• {translations.knowledgeBase2}</li>
            <li>• {translations.knowledgeBase3}</li>
            <li>• {translations.knowledgeBase4}</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Solutions;
