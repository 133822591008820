import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import Contact from './pages/Contact';
import { translations } from './translations';

function App() {
  const [language, setLanguage] = useState('zh');

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'zh' : 'en');
  };

  return (
    <Router>
      <div className="App">
        <div className="content-wrapper">
          <Header 
            language={language} 
            toggleLanguage={toggleLanguage} 
            translations={translations[language]} 
          />
          <Routes>
            <Route path="/" element={<Home language={language} translations={translations[language]} />} />
            <Route path="/solutions" element={<Solutions language={language} translations={translations[language]} />} />
            <Route path="/contact" element={<Contact language={language} translations={translations[language]} />} />
          </Routes>
        </div>
        <Footer translations={translations[language]} />
      </div>
    </Router>
  );
}

export default App;